import AssetItemMenu from './assets-explorer-collection-item-menu'
import AssetShare from '@modals/AssetShare'

export default {
  props: {
    asset: {
      required: true,
      type: Object
    },
    assets: {
      required: true,
      type: Array
    },
    activeFolder: {
      required: true,
      type: Object
    },
    canPreview: {
      required: true,
      type: Boolean
    },
    canSelect: {
      required: true,
      type: Boolean
    },
    canReplace: {
      required: true,
      type: Boolean
    },
    canDelete: {
      required: true,
      type: Boolean
    },
    canRename: {
      required: true,
      type: Boolean
    },
    showSize: {
      default: true,
      type: Boolean
    },
    showOptions: {
      default: true,
      type: Boolean
    },
    showComments: {
      default: true,
      type: Boolean
    },
    showType: {
      default: true,
      type: Boolean
    },
    showModified: {
      default: true,
      type: Boolean
    },
    dragger: {
      default: null,
      type: Object
    },
    isSelected: {
      default: false,
      type: Boolean
    },
    isMultiselected: {
      default: false,
      type: Boolean
    },
    isFocused: {
      default: false,
      type: Boolean
    },
    restrictedActionTypes: {
      type: Array,
      default: () => []
    },
    actionMode: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      thumbnailLoaded: false
    }
  },

  components: { AssetItemMenu },

  computed: {
    isEditable() {
      return (
        this.asset.type === 'scene' ||
        this.asset.type === 'sequence' ||
        this.asset.type === 'composition'
      )
    },

    folders: function () {
      return this.assets.filter(function (row) {
        return row.type === 'folder'
      })
    },

    isFolder: function () {
      return this.asset.type === 'folder'
    },

    isInRootFolder: function () {
      return this.activeFolder.parent === null
    },

    canOpenItem: function () {
      return this.isFolder
    },

    canShare: function () {
      return !this.isFolder
    },

    canMoveItem: function () {
      return this.canMoveItemUp || this.canMoveItemDown
    },

    canMoveItemUp: function () {
      return !this.isInRootFolder
    },

    canMoveItemDown: function () {
      return this.folders.length > 0
    },

    canViewItem: function () {
      return !this.isFolder
    },

    canReplaceItem: function () {
      return this.canReplace && !this.isFolder
    },

    canSelectItem: function () {
      return this.canSelect && !this.isFolder
    },

    canPreviewItem: function () {
      return this.canPreview && !this.isFolder
    },

    canRenameItem: function () {
      return this.canRename
    },

    canPerformAction: function () {
      return (
        this.canReplaceItem ||
        this.canRenameItem ||
        this.canDeleteItem ||
        this.canSelectItem ||
        this.canPreviewItem
      )
    },

    canDrag: function () {
      return this.dragger // && !this.isFolder
    },

    showSelectActionOption() {
      return this.hasRestrictedActionTypes
    },

    hasRestrictedActionTypes() {
      return this.restrictedActionTypes.length > 0
    },

    isSelectableWithinRestrictions() {
      return this.restrictedActionTypes.includes(this.asset.type)
    }
  },

  methods: {
    /**
     * "Delete asset" handler
     */
    onDelete: function () {
      this.$emit('delete-asset', { assets: [this.asset] })
    },

    onPreview: function () {
      this.$emit('preview-asset', this.asset)
    },

    onShare: function () {
      this.$bus.$emit('overlay:modal', {
        component: AssetShare,
        size: 'md',
        props: { asset: this.asset }
      })
    },

    /**
     * "Delete asset" handler
     */
    onToggleMultiselect: function (e) {
      e.preventDefault()

      if (!this.isMultiselected) {
        this.$emit('multiselect-asset', this.asset)
      } else {
        this.$emit('multideselect-asset', this.asset)
      }
    },

    /**
     * "Replace asset" handler
     */
    onReplace: function () {
      this.$emit('replace-asset', this.asset)
    },

    /**
     * "Deselect asset" handler
     */
    onDeselect: function () {
      this.$emit('deselect-asset', this.asset)
    },

    /**
     * "move asset" handler
     */
    onMoveDirect: function (folderId) {
      this.$emit('move-asset', { assets: [this.asset], folderId: folderId })
    },

    /**
     * "move asset" handler
     */
    onMoveChoice: function () {
      this.$emit('move-asset', { assets: [this.asset], folders: this.folders })
    },

    /**
     * "Rename asset" handler
     */
    onRename: function () {
      this.$emit('rename-asset', this.asset)
    },

    /**
     * "End drag" handler
     *
     * @param {Event} event
     */
    onDragEnd: function (event) {
      if (this.canDrag) {
        this.$emit('drop-asset', this.asset)
        this.dragger.end(event, this.asset)
      }
    },

    /**
     * "Start drag" handler
     *
     * @param {Event} event
     */
    onDragStart: function (event) {
      if (this.canDrag) {
        this.$emit('drag-asset', this.asset)
        this.dragger.start(event, this.asset)
      }
    },

    /**
     * "drag over" handler
     *
     * @param {Event} event
     */
    onDragOver: function (event) {
      if (this.canDrag) {
        if (this.isFolder) {
          this.$emit('drag-asset-focus', this.asset)
        } else {
          // We set this to null here, rather than on the dragLeave handler
          // to stop accidentally keeping foucs on a folder on the next drag
          this.$emit('drag-asset-focus', null)
        }
      }
    },

    /**
     * "drag over" handler
     *
     * @param {Event} event
     */
    onDragLeave: function (event) {
      if (this.canDrag && this.isFolder) {
        // Nothing to do here
      }
    },

    /**
     * "Select asset" handler
     */
    onSelect: function (mode) {
      this.$emit('select-asset', { asset: this.asset, mode: mode })
    },

    /**
     * Thumbnail loaded handler
     */
    onThumbnailLoad: function () {
      this.thumbnailLoaded = true
    },

    /**
     * "Select / deselect asset" handler
     */
    onToggleSelect: function () {
      this.isSelected ? this.onDeselect() : this.onSelect()
    },

    /**
     * "View asset" handler
     */
    onView: function () {
      this.$emit('view-asset', this.asset)
    }
  }
}
