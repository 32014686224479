<template>
  <div
    :draggable="canDrag"
    class=""
    @dblclick="onSelect"
    @click.prevent.stop="onSelect"
    @dragend="onDragEnd"
    @dragstart="onDragStart"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
  >
    <div class="text-xs my-1 px-2 text-gray-300 select-none">
      <div class="flex justify-between pb-1 border-b border-gray-700 hover:bg-gray-700">
        <div class="left-side flex-auto truncate pr-2">
          <a
            href="#"
            class="cursor-pointer hover:text-white px-1 py-1 flex items-center"
          >
            <AssetItemThumb
              :size="'xs'"
              :asset="asset"
            />
          </a>
        </div>

        <div class="right-side flex justify-between items-center mr-2 ">
          <div
            v-if="canPreview && !isFolder"
            class="btn btn-no-style cursor-pointer hover:text-white text-gray-200"
            @click.prevent.stop="onPreview"
          >
            <fa-icon
              icon="magnifying-glass"
              size="sm"
              class="fa-fw"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import item from './assets-explorer-collection-item-mixin.js'

const AssetItemThumb = () => import('@components/assets/asset-item-thumb')
const ProfileAvatarThumb = () => import('@components/profile/ProfileAvatarThumb')

export default {

  components: {
    AssetItemThumb,
    ProfileAvatarThumb
  },
  mixins: [item],

  computed: {
    canDrag () {
      return this.dragger && !this.isFolder
    }
  }
}

</script>
