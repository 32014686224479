<template>
  <div class="w-full">
    <div class="px-4 pt-4 md:px-6 md:pt-6">
      <!-- Modal Header -->
      <div
        class="flex flex-col items-center justify-start pb-4 border-b border-neutral-600 md:pb-6 sm:flex-row"
      >
        <div
          class="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-purple-600 rounded-full ring-2 ring-transparent ring-purple-400"
        >
          <fa-icon icon="link" class="text-purple-100" />
        </div>
        <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            class="text-lg font-medium leading-6 truncate text-neutral-100"
            id="modal-title"
          >
            Share Asset
          </h3>
          <div class="mt-2 truncate">
            <p class="text-sm text-neutral-400">
              Create a share link for the asset
            </p>
          </div>
        </div>
      </div>

      <!-- Modal Content -->
      <div
        class="my-4 space-y-4 md:space-y-6 md:my-6"
      >
        <div
          class="p-4 mt-4 text-sm border rounded-md border-neutral-600"
        >
          <AssetItemThumb v-bind:asset="asset" />
        </div>
        <div class="mt-4">
          <ShareLinkForm v-if="view === 'link'" v-bind:asset="asset" />
        </div>
      </div>
    </div>
    <!-- Modal Footer -->
    <div
      class="flex flex-row w-full p-4 space-x-4 border-t bg-neutral-800 border-neutral-600 md:space-x-reverse md:flex-row-reverse md:justify-start"
    >
      <button
        type="button"
        @click="$emit('close')"
        class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium bg-blue-700 shadow-sm borderrounded-md shrink-0 md:flex-auto md:flex-grow-0 focus:outline-none focus:ring-offset-2 text-neutral-100 ring-offset-0 hover:bg-blue-600 focus:ring-1 focus:border-blue-600 focus:ring-blue-400"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
const ShareLinkForm = () => import('@components/share/ShareLinkForm')
const ShareEmailForm = () => import('@components/share/ShareEmailForm')

const AssetItemThumb = () => import('@components/assets/asset-item-thumb')

export default {
  name: 'AssetShare',

  components: { AssetItemThumb, ShareLinkForm, ShareEmailForm },

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      view: 'link',

      showOptions: false,
      form: new SparkForm({}),

      errors: null
    }
  },

  computed: {
    formReadyToSumbit () {
      return true
    }
  },

  methods: {

    onSubmit () {
      if (!this.formReadyToSumbit) {
        this.form.setErrors({
          'form': ['Enter a valid email to invite to this team']
        })
        return
      }

      this.form.startProcessing()
      this.$store
        .dispatch('inviteTeamByEmail', {
          // email: this.email,
          // role: this.role,
          // message: this.message,
          // team: this.team
        })
        .then(response => {
          this.form.finishProcessing()
          if (!response.data.success) {
            alert.error('There was a problem sending the invites')
            this.form.setErrors(response.data.errors)
            this.errors = response.data.errors
          } else {
            this.email = ''
            this.message = null

            alert.success('Invitations sent')
            this.$emit('close')
          }
        })
        .catch(response => {
          this.form.finishProcessing()
          this.form.setErrors(response.data)
          this.errors = response.data.errors
        })
    }
  }
}
</script>
