<template>
  <v-popover>
    <a
      href="#"
      class="text-indigo-600 hover:text-indigo-900"
      @click.prevent
    >
      <fa-icon icon="caret-down" />
    </a>

    <template slot="popover">
      <ul class="text-sm leading-loose text-white">
        <li v-if="canShare">
          <a
            v-close-popover
            href="#"
            class="flex items-center justify-between hover:underline"
            @click.prevent="$emit('share-asset')"
          >
            Sharing
            <fa-icon
              class="ml-3 fa-fw"
              icon="link"
            />
          </a>
        </li>

        <li v-if="canPreview">
          <a
            v-close-popover
            href="#"
            class="flex items-center justify-between hover:underline"
            @click.prevent="$emit('preview-asset')"
          >
            Preview
            <fa-icon
              class="ml-3 fa-fw"
              icon="magnifying-glass"
            />
          </a>
        </li>

        <!-- <li v-if="canReplace">
          <a href="#" @click.prevent="$emit('upload-new-version')" v-close-popover class="flex items-center justify-between hover:underline">
            Upload New Version <fa-icon class="ml-3 fa-fw" icon="file-upload" />
          </a>
        </li>-->

        <li>
          <a
            v-close-popover
            href="#"
            class="flex items-center justify-between hover:underline"
            @click.prevent="$emit('rename-asset')"
          >
            Rename
            <fa-icon
              class="ml-3 fa-fw"
              icon="pencil"
            />
          </a>
        </li>

        <li v-if="canMoveItemUp">
          <a
            v-close-popover
            href="#"
            class="flex items-center justify-between hover:underline"
            @click.prevent="$emit('move-asset')"
          >
            Move up one level
            <fa-icon
              class="ml-3 fa-fw"
              icon="level-up"
            />
          </a>
        </li>

        <li v-if="canDelete">
          <a
            v-close-popover
            href="#"
            class="flex items-center justify-between hover:underline"
            @click.prevent="$emit('delete-asset')"
          >
            Delete
            <fa-icon
              class="ml-3 text-red-500 fa-fw"
              icon="trash-can"
            />
          </a>
        </li>
      </ul>
    </template>
  </v-popover>
</template>
<script>
export default {
  name: 'AssetsExplorerCollectionItemMenu',

  props: {
    canPreview: {
      type: Boolean,
      default: false
    },
    canShare: {
      type: Boolean,
      default: false
    },
    canReplace: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canMoveItemUp: {
      type: Boolean,
      default: false
    },
    parentFolderId: {
      type: String
    }
  }
}
</script>
